import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, TextField, Button, Typography } from "@mui/material";
import logo from "./logo.png";

function LoginPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  console.log("Login rendered");

  function handleSubmit(event) {
    event.preventDefault();
    // This is where you would authenticate the user using an API call or other mechanism
    // For this example, we'll assume that the username is "admin" and the password is "password"
    if (username === "admin" && password === "password") {
      // Set an "auth" cookie with a value of "true" to indicate that the user is authenticated
      document.cookie = "auth=true; Path=/;";
      console.log("cookie", document.cookie)
      setIsAuthenticated(true);
      // Redirect the user to the home page
      navigate("/");
    } else {
      alert("Invalid username or password");
    }
  }

  return (
    <div className="login-page-container">
      <div style={{ alignItems: "center", marginBottom: "24px", align: "center" }}>
        <img src={logo} alt="Logo" style={{ height: "64px", marginRight: "16px" }} />
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Farm2Farmlite Admin
        </Typography>
      </div>
      <Typography variant="h4" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              label="Username"
              variant="outlined"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Log in
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default LoginPage;

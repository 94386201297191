import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, List, ListItem, ListItemText } from "@mui/material";
import logo from "./logo.png";

function HomePage({ setIsAuthenticated }) {
  const navigate = useNavigate();

  function handleLogout() {
    // Clear the authentication cookie
    document.cookie = "auth=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    setIsAuthenticated(false)
    
    // Redirect the user to the login page
    navigate("/login");
  }

  function handleViewUsers() {
    // Navigate to the "View Users" page
    navigate("/view-users");
  }

  function handleUpdateSources() {
    // Navigate to the "Update Sources" page
    navigate("/update-sources");
  }


  return (
    <div style={{ padding: "24px" }}>
      <div style={{ alignItems: "center", marginBottom: "24px", align: "center" }}>
        <img src={logo} alt="Logo" style={{ height: "64px", marginRight: "16px" }} />
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Farm2Farmlite Admin
        </Typography>
      </div>
      <List style ={{marginLeft: "600px"}}>
        <ListItem>
          <Button variant="contained" color="primary" onClick={handleViewUsers}>
            <ListItemText primary="View Users" />
          </Button>
        </ListItem>
        <ListItem>
          <Button variant="contained" color="primary" onClick={handleUpdateSources}>
            <ListItemText primary="Update Sources" />
          </Button>
        </ListItem>
      </List>
      <Button variant="contained" onClick={handleLogout}>
        Logout
      </Button>
    </div>
  );
}

export default HomePage;

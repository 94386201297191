import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

function UpdateSourcesPage() {
  const [sourceName, setSourceName] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  function handleAddSource() {
    // Make the POST request to add the new source
    console.log(sourceName);
    fetch("https://farmtofarmlite-server.onrender.com/chat/source", {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ source: sourceName })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.message);
        }
        setSnackbarMessage("Source added successfully");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Source already exists");
        console.log(error)
        setOpenSnackbar(true);
      });
  }

  function handleSnackbarClose() {
    setOpenSnackbar(false);
  }

  return (
    <div style={{ padding: "24px" }}>
      <Typography variant="h5" style={{ fontWeight: "bold" }}>
        Add New Source
      </Typography>
      <TextField
        label="Source Name"
        value={sourceName}
        onChange={(event) => setSourceName(event.target.value)}
        style={{ marginBottom: "16px", marginTop: "16px" }}
      />
      <Button style={{ marginBottom: "16px", marginTop: "24px", marginLeft: "10px" }} variant="contained" color="primary" onClick={handleAddSource}>
        Add Source
      </Button>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes("successfully") ? "success" : "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UpdateSourcesPage;

import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { default as LoginPage } from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import ViewUsersPage from "./pages/ViewUsersPage";
import UpdateSourcesPage from "./pages/UpdateSourcesPage";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authCookie = document.cookie.split(";").find((cookie) => cookie.startsWith("auth="));
    const isAuthenticated = authCookie && authCookie.split("=")[1] === "true";
    setIsAuthenticated(isAuthenticated);
  }, []);
  console.log(isAuthenticated)
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/home" /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/home" /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />} />    
          <Route path="/home" element={isAuthenticated ? <HomePage setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/" />} />
          <Route path="/view-users" element={isAuthenticated ? <ViewUsersPage /> : <Navigate to="/" />} />
          <Route path="/update-sources" element={isAuthenticated ? <UpdateSourcesPage /> : <Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

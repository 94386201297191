import React, { useEffect, useState } from "react";
import logo from "./logo.png";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography 
} from "@mui/material";

function ViewUsersPage() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Make the API call to get the users data
    fetch("https://farmtofarmlite-server.onrender.com/user/all", {mode:'cors'})
      .then((response) => response.json())
      .then((data) => setUsers(data));
  }, []);

  return (
    <div className="login-page-container">
        <div style={{ alignItems: "center", marginBottom: "24px", align: "center" }}>
        <img src={logo} alt="Logo" style={{ height: "64px", marginRight: "16px" }} />
        <Typography variant="h5" style={{ fontWeight: "bold" }}>
          Farm2Farmlite Users
        </Typography>
      </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Username</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>First Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Last Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Password</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.username}>
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>{user.password}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}

export default ViewUsersPage;
